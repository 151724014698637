#first_cont{
    text-align: left;
    margin-top: 50px; 
}
#video{
    aspect-ratio: 16/9;
    background-color: black;
}

.passion{
    aspect-ratio: 16/9;
    background-color: black;
    min-height: 40vh;

}

.small_text{
    text-align: center;
}


.menu1{
    background-color:#c289c0 !important;
    border-color: transparent !important;
    border-bottom-width: 1px !important;
    border-bottom-color: black !important;
}

.list-group-item:hover {
    background-color: #977596;
}





.passion::-webkit-scrollbar {
    display: none;
  }



