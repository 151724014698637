.img1 {
    height: 100%;
}


#second_cont{
    margin-top: 160px;
    min-height: 100vh;
}

#info_cont{
    text-align: left;
    padding-top: 50px;
    padding-bottom: 10px;
}

#creds_Resume{
    text-aligh: center;
    padding-top: 50px;

}

#img{
height: 40vh;
width: 40vh;
}

@media (max-width: 750px) {
    button:hover,
    button:focus {
        background-color: transparent !important; /* Disable background color change on hover and focus */
        border: none !important; /* Ensure no border is shown */
        box-shadow: none !important; /* Ensure no shadow is shown */
    }

    .hidden-mobile {
        display: none; /* Hide elements with the hidden-mobile class */
    }
}

button:hover{
    background-color: rgba(0, 0, 0, 0.5);
}



button:focus{
    background-color: rgba(0, 0, 0, 0.5);
}
  
.button{
    border-radius: 50%;
}


.flex-items {
    display: inline-block;
    background-color: transparent;
    color: white;
    border: none;
    font-size:larger;
    border-bottom: solid 1px;
    width: 15%; 
    margin: 40px;
    justify-content: left;
}

.display{
    color: white;
    background-color: rgba(0, 0, 0, 0.9); ;
    width: 95vw ;
    min-height: 50vh;
    height: auto;
}



 
